<template>
  <v-container
    class="pa-0"
    style="overflowY: auto; height: 100%; max-width: 360px;"
    fluid
  >
    <v-card>
      <v-list>
        <v-list-item class="py-1">
          <v-select
            v-model="value.sortBy"
            hide-details
            :items="sortKeys"
            :label="$t('deconve.sortBy')"
            @change="onFilterChanged"
          />
        </v-list-item>

        <v-list-item class="py-1">
          <v-select
            v-model="value.sortOrder"
            hide-details
            :items="orderKeys"
            :label="$t('deconve.sortOrder')"
            @change="onFilterChanged"
          />
        </v-list-item>

        <v-list-item class="py-1">
          <v-select
            v-model="value.status"
            hide-details
            :items="showModeKeys"
            :label="$t('deconve.showNotifications')"
            @change="onFilterChanged"
          />
        </v-list-item>

        <v-list-item class="py-1">
          <v-select
            v-model="value.reviewStatus"
            hide-details
            :items="reviewStatusKeys"
            :label="$t('deconve.revision')"
            @change="onFilterChanged"
          />
        </v-list-item>

        <v-list-item class="py-1">
          <v-col>
            <v-row>
              <span>
                {{ $t('deconve.unit') }}
              </span>
            </v-row>
            <v-row
              v-if="value.unitName === undefined"
              class="align-center"
            >
              <v-btn
                outlined
                color="neutral"
                :fab="$vuetify.breakpoint.mobile"
                :x-small="$vuetify.breakpoint.mobile"
                @click="openUnitModal"
              >
                <v-icon :left="!$vuetify.breakpoint.mobile">
                  {{ icons.addUnit }}
                </v-icon>

                <div v-if="!$vuetify.breakpoint.mobile">
                  {{ $t('deconve.unit') }}
                </div>
              </v-btn>
            </v-row>

            <v-row
              v-else
              class="py-1 align-center"
            >
              <v-chip
                label
                outlined
                class="pa-1"
              >
                {{ value.unitName }}
              </v-chip>
              <v-btn
                outlined
                fab
                x-small
                color="neutral"
                class="ml-1"
                @click="openUnitModal"
              >
                <v-icon>
                  {{ icons.addUnit }}
                </v-icon>
              </v-btn>
            </v-row>
          </v-col>
        </v-list-item>

        <v-list-item class="py-1">
          <v-col>
            <v-row>
              <span>
                {{ $t('deconve.people') }}
              </span>
            </v-row>

            <v-row v-if="value.personId">
              <v-chip
                close
                @click:close="deleteFilterByPerson"
              >
                {{ value.personName }}
              </v-chip>
            </v-row>

            <v-row
              v-if="value.peopleTags.length === 0"
              class="py-1 align-center"
            >
              <v-btn
                outlined
                color="neutral"
                :fab="$vuetify.breakpoint.mobile"
                :x-small="$vuetify.breakpoint.mobile"
                @click="openPeopleTagModal"
              >
                <v-icon :left="!$vuetify.breakpoint.mobile">
                  {{ icons.addTag }}
                </v-icon>

                <div v-if="!$vuetify.breakpoint.mobile">
                  {{ $t('deconve.tags') }}
                </div>
              </v-btn>

              <v-switch
                v-model="value.noPeopleTags"
                inset
                hide-details
                class="ma-0 ml-4"
                :label="$t('deconve.noTags')"
                @change="onFilterChanged"
              />
            </v-row>

            <v-row
              v-else
              class="py-1 align-center"
            >
              <tag
                v-for="tagId in value.peopleTags"
                :key="tagId"
                class="pa-1"
                :tag-id="tagId"
              />

              <v-btn
                outlined
                fab
                x-small
                color="neutral"
                class="ml-1"
                @click="openPeopleTagModal"
              >
                <v-icon>
                  {{ icons.addTag }}
                </v-icon>
              </v-btn>
            </v-row>
          </v-col>
        </v-list-item>

        <v-list-item class="py-1">
          <v-col>
            <v-row>
              <span>
                {{ $t('deconve.notifications') }}
              </span>
            </v-row>

            <v-row
              v-if="value.notificationTags.length === 0"
              class="py-1 align-center"
            >
              <v-btn
                outlined
                color="neutral"
                :fab="$vuetify.breakpoint.mobile"
                :x-small="$vuetify.breakpoint.mobile"
                @click="openNotificationTagModal"
              >
                <v-icon :left="!$vuetify.breakpoint.mobile">
                  {{ icons.addTag }}
                </v-icon>

                <div v-if="!$vuetify.breakpoint.mobile">
                  {{ $t('deconve.tags') }}
                </div>
              </v-btn>
              <v-switch
                v-model="value.noNotificationTags"
                inset
                hide-details
                class="ma-0 ml-4"
                :label="$t('deconve.noTags')"
                @change="onFilterChanged"
              />
            </v-row>

            <v-row
              v-else
              class="py-1 align-center"
            >
              <tag
                v-for="tagId in value.notificationTags"
                :key="tagId"
                class="pa-1"
                :tag-id="tagId"
              />
              <v-btn
                outlined
                fab
                x-small
                color="neutral"
                class="ml-1"
                @click="openNotificationTagModal"
              >
                <v-icon>
                  {{ icons.addTag }}
                </v-icon>
              </v-btn>
            </v-row>
          </v-col>
        </v-list-item>
      </v-list>
      <v-card-actions class="justify-end">
        <v-btn
          color="primary"
          :disabled="!hasChanged"
          :small="$vuetify.breakpoint.mobile"
          @click="clearFilters"
        >
          {{ $t('deconve.clearFilters') }}
        </v-btn>
      </v-card-actions>
    </v-card>

    <tags-manager-dialog ref="tagDialog" />
    <unit-selection-dialog ref="unitDialog" />
  </v-container>
</template>

<script>
// Copyright (C) 2023 Deconve Technology. All rights reserved.

import { mdiTagPlusOutline, mdiStorePlusOutline } from '@mdi/js';
import TagsManagerDialog from '@/components/TagsManagerDialog.vue';
import UnitSelectionDialog from '@/components/UnitSelectionDialog.vue';
import Tag from '@/components/Tag.vue';
import { NOTIFICATION_REVIEW_STATUS, NOTIFICATION_STATUS, SORT_ORDER } from '@/utils/faceidFilters';

export default {
  name: 'NotificationsFilter',
  components: {
    TagsManagerDialog,
    UnitSelectionDialog,
    Tag,
  },
  props: {
    value: { type: Object, required: true },
    hasChanged: { type: Boolean, default: false },
  },
  data() {
    return {
      sortKeys: [
        { text: this.$t('deconve.date'), value: 'created_at' },
        { text: this.$t('deconve.personName'), value: 'person_name' },
        { text: this.$t('deconve.video'), value: 'video_name' },
      ],
      reviewStatusKeys: [
        { text: this.$t('deconve.allFemale'), value: NOTIFICATION_REVIEW_STATUS.all },
        { text: this.$t('deconve.truePositive'), value: NOTIFICATION_REVIEW_STATUS.yes },
        { text: this.$t('deconve.falsePositive'), value: NOTIFICATION_REVIEW_STATUS.no },
        { text: this.$t('deconve.unreviewed'), value: NOTIFICATION_REVIEW_STATUS.unreviewed },
      ],
      orderKeys: [
        { text: this.$t('deconve.ascendingOrder'), value: SORT_ORDER.ascending },
        { text: this.$t('deconve.descendingOrder'), value: SORT_ORDER.descending },
      ],
      showModeKeys: [
        { text: this.$t('deconve.allFemale'), value: NOTIFICATION_STATUS.all },
        { text: this.$t('deconve.actives'), value: NOTIFICATION_STATUS.activated },
        { text: this.$t('deconve.inTrash'), value: NOTIFICATION_STATUS.inTrash },
      ],
      icons: {
        addTag: mdiTagPlusOutline,
        addUnit: mdiStorePlusOutline,
      },
    };
  },
  methods: {
    clearFilters() {
      this.$emit('clear');
    },
    openNotificationTagModal() {
      // We are updating the code style to use only the list of tag ids. After update the tag
      // dialog, we will remove this part
      const currentTags = this.value.notificationTags.map((id) => ({ id }));

      this.$refs.tagDialog.open(currentTags).then((tags) => {
        if (tags) {
          this.value.notificationTags = tags.map(({ id }) => id);
          this.onFilterChanged();
        }
      });
    },
    openPeopleTagModal() {
      // We are updating the code style to use only the list of tag ids. After update the tag
      // dialog, we will remove this part
      const currentTags = this.value.peopleTags.map((id) => ({ id }));

      this.$refs.tagDialog.open(currentTags).then((tags) => {
        if (tags) {
          this.value.peopleTags = tags.map(({ id }) => id);
          this.onFilterChanged();
        }
      });
    },
    openUnitModal() {
      this.$refs.unitDialog.open(this.value.unitId).then((selectedUnit) => {
        this.value.unitId = selectedUnit?.id;
        this.value.unitName = selectedUnit?.name;
        this.onFilterChanged();
      }).catch(() => {
        // User canceled the dialog
      });
    },
    onFilterChanged() {
      this.$emit('change');
    },
    deleteFilterByPerson() {
      this.value.personId = undefined;
      this.value.personName = undefined;
      this.onFilterChanged();
    },
  },
};
</script>
