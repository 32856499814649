<template>
  <v-container
    class="pa-0"
    style="overflow-y: auto; height: 100%; width: 100%"
    fluid
  >
    <v-data-iterator
      class="d-flex flex-column"
      style="height: 100%"
      :items="notifications.items"
      :loading="isLoading"
      :server-items-length="notifications.total"
      hide-default-footer
      @update:page="onPageNumberUpdated"
    >
      <template v-slot:header>
        <div
          class="pa-3 pb-4 d-flex align-center"
          style="border-bottom: 1px solid;"
          :style="{
            borderColor: $vuetify.theme.themes.light.border,
          }"
        >
          <input-text
            v-model="search"
            max-width="440px"
            left-icon="mdi-magnify"
            :placeholder="$t('deconve.search')"
          />

          <v-menu
            :close-on-content-click="false"
            offset-y
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                icon
                :color="hasFilterChanged ? 'primary' : 'neutral'"
                :class="hasFilterChanged ? 'primaryHighlight' : 'white'"
                v-bind="attrs"
                class="mx-2"
                :small="$vuetify.breakpoint.mobile"
                v-on="on"
              >
                <v-icon>
                  {{ hasFilterChanged ? 'mdi-filter':'mdi-filter-outline' }}
                </v-icon>
              </v-btn>
            </template>

            <notifications-filter
              v-model="filterOptions"
              :has-changed="hasFilterChanged"
              @change="onFilterChanged"
              @clear="clearFilters"
            />
          </v-menu>

          <date-time-range-picker
            :start-date-time="filterOptions.createdAfter"
            :end-date-time="filterOptions.createdBefore"
            :mode="filterOptions.createdDateOption"
            @change="onCreatedAtDateChanged"
          />
        </div>
      </template>

      <template v-slot:loading>
        <span class="ml-1 pa-2">
          {{ $t('deconve.loading') }}
        </span>
      </template>

      <template v-slot:no-data>
        <span
          class="ml-1 pa-2 neutralPrimary--text
        text-subtitle-2 text-sm-subtitle-1 font-weight-bold"
        >
          {{ $t('deconve.noNotification') }}
        </span>
      </template>

      <template v-slot:default="props">
        <div
          style="flex: 1; overflow-y: auto;"
          :style="{
            backgroundColor: $vuetify.theme.themes.light.background,
          }"
        >
          <v-progress-linear
            v-if="isLoading"
            :indeterminate="true"
          />
          <div class="d-flex flex-wrap pa-2">
            <v-col
              v-for="item in props.items"
              :key="item.id"
              class="pa-1"
              style="height: fit-content"
              cols="12"
              sm="6"
              md="4"
              lg="3"
            >
              <notification-preview
                :id="item.id"
                :key="item.id"
                :data="item"
              />
            </v-col>
          </div>
        </div>
      </template>
      <template v-slot:footer="props">
        <footer-data-iterator
          :items-per-page-array="itemsPerPageArray"
          :items-per-page="itemsPerPage"
          :page="page"
          :total-data="props.pagination.itemsLength"
          @changeItensPerPage="updateItemsPerPage"
          @changePage="onPageNumberUpdated"
        />
      </template>
    </v-data-iterator>
  </v-container>
</template>

<script>
// Copyright (C) 2023 Deconve Technology. All rights reserved.

import { mapActions, mapGetters } from 'vuex';

import { NOTIFICATION_REVIEW_STATUS, NOTIFICATION_STATUS, SORT_ORDER } from '@/utils/faceidFilters';
import { DATE_TIME_OPTIONS, getDateAndTimeRange } from '@/utils/dateTimeRange';

import DateTimeRangePicker from '@/components/DateTimeRangePicker.vue';
import NotificationPreview from '@/components/NotificationPreview.vue';
import InputText from '@/components/InputText.vue';

import FooterDataIterator from '@/components/FooterDataIterator.vue';
import NotificationsFilter from './NotificationsFilter.vue';

function defaultFilterOptions() {
  const createdDateOption = DATE_TIME_OPTIONS.lastTwoDays;
  const {
    startDate: createdAfter, endDate: createdBefore,
  } = getDateAndTimeRange(createdDateOption);

  return {
    createdBefore,
    createdAfter,
    createdDateOption,
    sortBy: 'created_at',
    sortOrder: SORT_ORDER.descending,
    status: NOTIFICATION_STATUS.activated,
    reviewStatus: NOTIFICATION_REVIEW_STATUS.all,
    noNotificationTags: false,
    notificationTags: [],
    noPeopleTags: false,
    peopleTags: [],
    unitId: undefined,
    unitName: undefined,
    personId: undefined,
    personName: undefined,
  };
}

export default {
  components: {
    NotificationPreview,
    DateTimeRangePicker,
    NotificationsFilter,
    FooterDataIterator,
    InputText,
  },
  data() {
    return {
      isLoading: false,
      itemsPerPageArray: [4, 8, 12],
      search: undefined,
      page: 1,
      itemsPerPage: 8,
      updateNotificationListTimer: '',
      filterOptions: defaultFilterOptions(),
    };
  },
  computed: {
    ...mapGetters({
      notifications: 'faceid/getNotifications',
      dataIteratorSettings: 'faceid/getNotificationIteratorSettings',
    }),
    hasFilterChanged() {
      return Boolean(
        this.filterOptions.sortBy !== 'created_at'
        || this.filterOptions.sortOrder !== 'descending'
        || this.filterOptions.status !== NOTIFICATION_STATUS.activated
        || this.filterOptions.reviewStatus !== NOTIFICATION_REVIEW_STATUS.all
        || this.filterOptions.createdDateOption !== DATE_TIME_OPTIONS.lastTwoDays
        || this.filterOptions.noNotificationTags
        || this.filterOptions.notificationTags.length > 0
        || this.filterOptions.noPeopleTags
        || this.filterOptions.peopleTags.length > 0
        || this.filterOptions.unitId
        || this.filterOptions.unitName
        || this.filterOptions.personId,
      );
    },
  },
  watch: {
    search() {
      this.onFilterChanged();
    },
  },
  created() {
    if (this.$route.params.personId) {
      const {
        personId, personName, reviewStatus, status,
      } = this.$route.params;

      this.filterOptions.personId = personId;
      this.filterOptions.personName = personName;
      this.filterOptions.reviewStatus = reviewStatus;
      this.filterOptions.status = status;
    } else {
      this.getDataIteratorInfo();
    }

    this.onFilterChanged();
    this.setTimerToUpdateNotificationList();
  },
  beforeDestroy() {
    clearInterval(this.updateNotificationListTimer);
  },
  methods: {
    ...mapActions({
      fetchNotifications: 'faceid/fetchNotifications',
      saveNotificationIteratorSettings: 'faceid/setNotificationIteratorSettings',
    }),
    clearFilters() {
      this.filterOptions = defaultFilterOptions();
      this.page = 1;
      this.onFilterChanged();
    },
    updateItemsPerPage(number) {
      this.itemsPerPage = number;
      this.page = 1;
      this.onFilterChanged();
    },
    getDataIteratorInfo() {
      if (this.dataIteratorSettings) {
        const {
          page,
          search,
          sortOrder,
          itemsPerPage,
          sortBy,
          reviewStatus,
          createdDateOption,
          createdAfter,
          createdBefore,
          inTrash,
          noNotificationTags,
          notificationTags,
          noPeopleTags,
          peopleTags,
          unitId,
          unitName,
          personId,
          personName,
        } = this.dataIteratorSettings;

        let status;

        // Data-iterator updates the page value if it is greater than the number of pages availables
        if (page) {
          switch (inTrash) {
            case undefined:
              status = NOTIFICATION_STATUS.all;
              break;
            case true:
              status = NOTIFICATION_STATUS.inTrash;
              break;
            case false:
              status = NOTIFICATION_STATUS.activated;
              break;
            default:
              break;
          }

          this.page = page;
          this.itemsPerPage = itemsPerPage;
          this.search = search;

          this.filterOptions = {
            sortBy,
            sortOrder,
            status,
            reviewStatus: reviewStatus || NOTIFICATION_REVIEW_STATUS.all,
            createdDateOption,
            createdAfter,
            createdBefore,
            noNotificationTags,
            notificationTags,
            noPeopleTags,
            peopleTags,
            unitId,
            unitName,
            personId,
            personName,
          };
        }
      }
    },
    saveIteratorSettings() {
      const {
        sortBy,
        sortOrder,
        status,
        reviewStatus,
        createdDateOption,
        createdAfter,
        createdBefore,
        noNotificationTags,
        notificationTags,
        noPeopleTags,
        peopleTags,
        unitId,
        unitName,
        personId,
        personName,
      } = this.filterOptions;

      let inTrash;

      switch (status) {
        case NOTIFICATION_STATUS.inTrash:
          inTrash = true;
          break;
        case NOTIFICATION_STATUS.activated:
          inTrash = false;
          break;
        default:
          inTrash = undefined;
          break;
      }

      const dataIterator = {
        createdAfter,
        createdBefore,
        createdDateOption,
        reviewStatus: reviewStatus === NOTIFICATION_REVIEW_STATUS.all ? undefined : reviewStatus,
        inTrash,
        page: this.page,
        search: this.search,
        itemsPerPage: this.itemsPerPage,
        sortOrder,
        sortBy,
        noNotificationTags,
        notificationTags,
        noPeopleTags,
        peopleTags,
        unitId,
        unitName,
        personId,
        personName,
      };

      this.saveNotificationIteratorSettings(dataIterator);
    },
    handleFetchNotifications() {
      this.isLoading = true;
      this.fetchNotifications().then(() => {
        this.isLoading = false;
      }).catch(() => {
        this.isLoading = false;
      });
    },
    handleUpdateNotifications() {
      const { startDate, endDate } = getDateAndTimeRange(this.filterOptions.createdDateOption);

      if (startDate && endDate) {
        this.filterOptions.createdBefore = endDate;
        this.filterOptions.createdAfter = startDate;

        this.saveIteratorSettings();
      }

      this.handleFetchNotifications();
    },
    onFilterChanged() {
      this.saveIteratorSettings();
      this.handleFetchNotifications();
    },
    onPageNumberUpdated(page) {
      this.page = page;
      this.onFilterChanged();
    },
    onCreatedAtDateChanged({ startDate, endDate, mode }) {
      this.filterOptions.createdBefore = endDate;
      this.filterOptions.createdAfter = startDate;
      this.filterOptions.createdDateOption = mode;

      this.onFilterChanged();
    },
    setTimerToUpdateNotificationList() {
      // TODO(spidteam): using the chat service (socket.io), we can receive events at "real time".
      // Until we have a full validation of the socket service/integration, we'll let this part to
      // force a full update in the notification list.
      const fiveMinutes = 5 * 60 * 1000;

      this.updateNotificationListTimer = setInterval(this.handleUpdateNotifications, fiveMinutes);
    },
  },
};
</script>
